import {
  faChartLine,
  faDatabase,
  faHome,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import * as PropTypes from 'prop-types';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
// import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import BackgroundImage from 'gatsby-background-image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FormattedMessage } from 'react-intl';
import datArtLogo from '../../content/assets/datArtLogo.svg';
import Layout from '../components/Layout';
// import datArtHeader1 from '../../content/assets/datArtHeader1-1024x684.jpg';
// import datArtHeader2 from '../../content/assets/datArtHeader2-1024x684.jpg';
// import datArtHeader3 from '../../content/assets/datArtHeader3-1024x320.jpg';
// import datArtFeature1 from '../../content/assets/datArtFeature1-1024x682.jpg';
// import datArtFeature2 from '../../content/assets/datArtFeature2-1024x682.jpg';
// import datArtFeature3 from '../../content/assets/datArtFeature3.jpg';
// import datArtFeature4 from '../../content/assets/datArtFeature4.jpg';
import SEO from '../components/SEO';
import LocalizedLink from '../components/LocalizedLink';

// Import logos.
// import lseLogo from '../../content/assets/lseLogo.svg';

// // Messages.
// import en from '../i18n/en.json';
// import fr from '../i18n/fr.json';
//
// const messages = { en, fr };

export default function Home({ location, data, pathContext: { locale, pageSlugs } }) {
  const { markdownFileText: { frontmatter } } = data;

  return (
    // TODO: Create sub components.
    // TODO: Make a global component for container and title with standard page (all but not home
    //  page) + MAYBE TEMPLATE WITH MARKDOWN (PAGE AUTO GENERATE)
    <Layout location={location} locale={locale} pageSlugs={pageSlugs}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        language={locale}
      />
      {/* <Carousel id="home-page-carousel" className="mx-auto"> */}
      {/*  <Carousel.Item> */}
      {/*    /!* <img *!/ */}
      {/*    /!* height="300" *!/ */}
      {/*    /!* className="d-block min-vw-100 fit-image" *!/ */}
      {/*    /!* src={datArtHeader1} *!/ */}
      {/*    /!* alt="First slide" *!/ */}
      {/*    /!* /> *!/ */}
      {/*    <Img */}
      {/*      fixed={data.datArtHeader1Image.childImageSharp.fixed} */}
      {/*      alt="First slide" */}
      {/*      className="d-block min-vw-100 carousel-images-max-height" */}
      {/*    /> */}
      {/*    /!* <Carousel.Caption className="mb-5"> *!/ */}
      {/*    /!*  <h3>First slide label</h3> *!/ */}
      {/*    /!*  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> *!/ */}
      {/*    /!* </Carousel.Caption> *!/ */}
      {/*  </Carousel.Item> */}
      {/*  <Carousel.Item> */}
      {/*    /!* <img *!/ */}
      {/*    /!* height="300" *!/ */}
      {/*    /!* className="d-block min-vw-100 fit-image" *!/ */}
      {/*    /!* src={datArtHeader2} *!/ */}
      {/*    /!* alt="Third slide" *!/ */}
      {/*    /!* /> *!/ */}
      {/*    <Img */}
      {/*      fixed={data.datArtHeader2Image.childImageSharp.fixed} */}
      {/*      alt="Second slide" */}
      {/*      className="d-block min-vw-100 carousel-images-max-height" */}
      {/*    /> */}
      {/*    /!* <Carousel.Caption className="mb-5"> *!/ */}
      {/*    /!*  <h3>Second slide label</h3> *!/ */}
      {/*    /!*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> *!/ */}
      {/*    /!* </Carousel.Caption> *!/ */}
      {/*  </Carousel.Item> */}
      {/*  <Carousel.Item> */}
      {/*    /!* <img *!/ */}
      {/*    /!* height="300" *!/ */}
      {/*    /!* className="d-block min-vw-100 fit-image" *!/ */}
      {/*    /!* src={datArtHeader3} *!/ */}
      {/*    /!* alt="Third slide" *!/ */}
      {/*    /!* /> *!/ */}
      {/*    <Img */}
      {/*      fixed={data.datArtHeader3Image.childImageSharp.fixed} */}
      {/*      alt="Third slide" */}
      {/*      className="d-block min-vw-100 carousel-images-max-height" */}
      {/*    /> */}
      {/*    /!* <Carousel.Caption className="mb-5"> *!/ */}
      {/*    /!*  <h3>Third slide label</h3> *!/ */}
      {/*    /!*  <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> *!/ */}
      {/*    /!* </Carousel.Caption> *!/ */}
      {/*  </Carousel.Item> */}
      {/*  <Carousel.Item> */}
      {/*    <Img */}
      {/*      fixed={data.datArtHeader4Image.childImageSharp.fixed} */}
      {/*      alt="Fourth slide" */}
      {/*      className="d-block min-vw-100 carousel-images-max-height" */}
      {/*    /> */}
      {/*    /!* <Carousel.Caption className="mb-5"> *!/ */}
      {/*    /!*  <h3>Fourth slide label</h3> *!/ */}
      {/*    /!*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> *!/ */}
      {/*    /!* </Carousel.Caption> *!/ */}
      {/*  </Carousel.Item> */}
      {/* </Carousel> */}
      {/* <Img */}
      {/*  fixed={data.datArtHeader1Image.childImageSharp.fixed} */}
      {/*  alt="First slide" */}
      {/*  className="min-vw-100 carousel-images-max-height" */}
      {/* /> */}
      {/* <BackgroundImage */}
      {/*  Tag="div" */}
      {/*  // className="p-4 min-vh-100" */}
      {/*  className="container px-4 pb-4 min-vh-100 bg-position-tr" */}
      {/*  fluid={data.datArtHeader3Image.childImageSharp.fluid} */}
      {/*  // fixed={data.datArtHeader1Image.childImageSharp.fixed} */}
      {/*  // backgroundColor="#040e18" */}
      {/* > */}
      {/* <Carousel id="home-page-carousel" className="mx-auto align-middle"> */}
      {/*  <Carousel.Item> */}
      {/*    /!* <img *!/ */}
      {/*    /!* height="300" *!/ */}
      {/*    /!* className="d-block min-vw-100 fit-image" *!/ */}
      {/*    /!* src={datArtHeader1} *!/ */}
      {/*    /!* alt="First slide" *!/ */}
      {/*    /!* /> *!/ */}
      {/*    <Img */}
      {/*      fixed={data.datArtHeader1Image.childImageSharp.fixed} */}
      {/*      alt="First slide" */}
      {/*      className="d-block min-vw-100 carousel-images-max-height test" */}
      {/*    /> */}
      {/*    /!* <Carousel.Caption className="mb-5"> *!/ */}
      {/*    /!*  <h3>First slide label</h3> *!/ */}
      {/*    /!*  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> *!/ */}
      {/*    /!* </Carousel.Caption> *!/ */}
      {/*    <Carousel.Caption> */}
      {/*      <h1 className="text-dark"> */}
      {/*        Votre partenaire pour le déploiement ou l'évolution de vos outils SAP et Microsoft.
      */}
      {/*      </h1> */}
      {/*    </Carousel.Caption> */}
      {/*  </Carousel.Item> */}
      {/* </Carousel> */}

      <BackgroundImage
        tag="div"
        className="d-table w-100 h-350-px"
        // fixed={data.datArtHomeHeaderImage.childImageSharp.fixed}
        fluid={data.datArtHomeHeaderImage.childImageSharp.fluid}
        // backgroundColor="#040e18"
      >
        <div className="d-table-cell align-middle text-center">
          <h1
            className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
          >
            {frontmatter.mainTitle}
          </h1>
        </div>
      </BackgroundImage>

      {/* <Container className="px-4 pb-4 min-vh-100 bg-position-tr"> */}
      {/*  /!*<h1 className="text-info h-dynamic font-weight-600 w-h1-adaptive pt-4 m-auto
      text-center">*!/ */}
      {/*  /!*  /!* <FontAwesomeIcon className="mr-3" icon={faHome} size="xs" /> *!/*!/ */}
      {/*  /!*  Votre partenaire pour le déploiement ou l'évolution de vos outils SAP et*!/ */}
      {/*  /!*  Microsoft.*!/ */}
      {/*  /!*</h1>*!/ */}
      {/*  /!* <Card className="w-contact-box mt-4 border-0 special-shadow"> *!/ */}
      {/*  /!*  <Card.Body> *!/ */}
      {/*  /!*    <Card.Title as="h3">Contactez-nous</Card.Title> *!/ */}
      {/*  /!*    <Card.Text> *!/ */}
      {/*  /!*      Vous pouvez nous contacter par téléphone ou en utilisant notre formulaire de contact. *!/ */}
      {/*  /!*    </Card.Text> *!/ */}
      {/*  /!*    <LocalizedLink to="/contact/#contact" className="btn btn-primary"> *!/ */}
      {/*  /!*      <FormattedMessage id="navBarContact" /> *!/ */}
      {/*  /!*    </LocalizedLink> *!/ */}
      {/*  /!*  </Card.Body> *!/ */}
      {/*  /!* </Card> *!/ */}
      {/*  <h2 className="h3 text-center mt-5"> */}
      {/*    NOTRE EXPERTISE */}
      {/*  </h2> */}
      {/*  /!* <p className="text-center">{markdownFileText.frontmatter.textAfterSecondaryTitle}</p> */}
      {/*  *!/ */}
      {/*  /!* Three columns of text below the carousel *!/ */}
      {/*  <Row className="mt-4 justify-content-lg-around"> */}
      {/*    <Col lg={5} className="text-center border-grey p-2"> */}
      {/*      <FontAwesomeIcon icon={faDatabase} size="2x" className="text-primary" /> */}
      {/*      <h3 className="h4 text-primary mt-1">SAP</h3> */}
      {/*      <p className="lead"> */}
      {/*        Nous conduisons la réalisation de vos projets SAP et répondons à vos besoins de */}
      {/*        maintenance. */}
      {/*      </p> */}
      {/*    </Col> */}
      {/*    <Col lg={5} className="text-center border-grey p-2 mt-3 mt-lg-0"> */}
      {/*      <FontAwesomeIcon icon={faChartLine} size="2x" className="text-primary" /> */}
      {/*      <h3 className="h4 text-primary mt-1">Microsoft</h3> */}
      {/*      <p className="lead"> */}
      {/*        Nous déployons des solutions Microsoft standard ou spécifiques au sein de votre */}
      {/*        organisation et répondons à vos besoins de maintenance. */}
      {/*      </p> */}
      {/*    </Col> */}
      {/*    /!* <Col lg={4} className="text-center"> *!/ */}
      {/*    /!*  <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-info" /> *!/ */}
      {/*    /!*  <h4>{markdownFileText.frontmatter.paperPlaneIconCaption}</h4> *!/ */}
      {/*    /!*  <p>{markdownFileText.frontmatter.paperPlaneIconText}</p> *!/ */}
      {/*    /!* </Col> *!/ */}
      {/*  </Row> */}
      {/*  /!* <hr className="my-5" /> *!/ */}
      {/*  <h2 className="h3 text-center mt-5"> */}
      {/*    Nos engagements */}
      {/*  </h2> */}
      {/*  <Row className="mt-4 justify-content-lg-around"> */}
      {/*    <Col lg={3} className="p-1"> */}
      {/*      /!* <FontAwesomeIcon icon={faDatabase} size="3x" className="text-info" /> *!/ */}
      {/*      <h3 className="h4 text-info">Constance du service</h3> */}
      {/*      <p> */}
      {/*        DatArt sait fidéliser ses collaborateurs au sein d'une équipe stable et solide en */}
      {/*        vue d'assurer chez nos clients une parfaite continuité dans la qualité de service. */}
      {/*      </p> */}
      {/*    </Col> */}
      {/*    <Col lg={3} className="p-1"> */}
      {/*      /!* <FontAwesomeIcon icon={faChartLine} size="3x" className="text-info" /> *!/ */}
      {/*      <h3 className="h4 text-info">Des interlocuteurs experts</h3> */}
      {/*      <p> */}
      {/*        Les interlocuteurs référents de nos clients sont */}
      {/*        <strong> toujours </strong> */}
      {/*        des consultants disposant d'un haut niveau d'expérience et d'expertise. */}
      {/*      </p> */}
      {/*    </Col> */}
      {/*    <Col lg={3} className="p-1"> */}
      {/*      <h3 className="h4 text-info">Disponibilité et réactivité</h3> */}
      {/*      <p> */}
      {/*        Nous restons en contact étroit avec nos clients pendant toute la durée des projets, */}
      {/*        et sommes disponibles même en cas de sollicitation imprévue */}
      {/*      </p> */}
      {/*    </Col> */}
      {/*    /!* <Col lg={4} className="text-center"> *!/ */}
      {/*    /!*  <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-info" /> *!/ */}
      {/*    /!*  <h4>{markdownFileText.frontmatter.paperPlaneIconCaption}</h4> *!/ */}
      {/*    /!*  <p>{markdownFileText.frontmatter.paperPlaneIconText}</p> *!/ */}
      {/*    /!* </Col> *!/ */}
      {/*  </Row> */}
      {/* </Container> */}
      <div className="bg-info">
        <Container className="p-4">
          <div className="text-center">
            <h2 className="pl-2 left-border d-inline-block">{frontmatter.expertiseTitle}</h2>
          </div>
          {/* <p className="text-center">{markdownFileText.frontmatter.textAfterSecondaryTitle}</p>
        */}
          {/* Three columns of text below the carousel */}
          <Row className="mt-4 justify-content-lg-around">
            <Col lg={5} className="text-center p-3 rounded bg-white special-shadow">
              {/* <FontAwesomeIcon icon={faDatabase} size="4x" className="text-primary" /> */}
              {/* <h3 className="h3 text-white mt-1">SAP</h3> */}
              <LocalizedLink to="/sap/services/conseil-formation/">
                <h3 className="font-weight-bold d-inline-block underline-animation">
                  {frontmatter.sapTitle}
                </h3>
              </LocalizedLink>
              <p className="lead">{frontmatter.sapText}</p>
            </Col>
            {/* <Col lg={5} className="text-center p-3 mt-3 mt-lg-0 rounded bg-white special-shadow"> */}
            {/*  /!* <FontAwesomeIcon icon={faChartLine} size="4x" className="text-white" /> *!/ */}
            {/*  /!* <Img fluid={data.microsoftLogo.childImageSharp.fluid} className="h-25" /> *!/ */}
            {/*  <LocalizedLink to="/microsoft/conseil-formation/"> */}
            {/*    <h3 className="font-weight-bold d-inline-block underline-animation"> */}
            {/*      {frontmatter.microsoftTitle} */}
            {/*    </h3> */}
            {/*  </LocalizedLink> */}
            {/*  /!* <h3 className="h3 text-white mt-1">Microsoft</h3> *!/ */}
            {/*  <p className="lead">{frontmatter.microsoftText}</p> */}
            {/* </Col> */}
            {/* <Col lg={4} className="text-center"> */}
            {/*  <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-info" /> */}
            {/*  <h4>{markdownFileText.frontmatter.paperPlaneIconCaption}</h4> */}
            {/*  <p>{markdownFileText.frontmatter.paperPlaneIconText}</p> */}
            {/* </Col> */}
          </Row>
        </Container>
      </div>
      {/* <hr className="my-5" /> */}
      <div className="bg-grey">
        <Container className="p-4">
          <div className="text-center">
            <h2 className="pl-2 left-border d-inline-block">
              {frontmatter.commitmentsTitle}
            </h2>
          </div>
          <Row className="mt-4 justify-content-lg-around">
            <Col lg={3} className="p-1">
              {/* <FontAwesomeIcon icon={faDatabase} size="3x" className="text-info" /> */}
              <h3 className="h-test text-info">{frontmatter.serviceContinuityTitle}</h3>
              <p>{frontmatter.serviceContinuityText}</p>
            </Col>
            <Col lg={3} className="p-1">
              {/* <FontAwesomeIcon icon={faChartLine} size="3x" className="text-info" /> */}
              <h3 className="h-test text-info">{frontmatter.expertConsultantsTitle}</h3>
              <p>{frontmatter.expertConsultantsText}</p>
            </Col>
            <Col lg={3} className="p-1">
              <h3 className="h-test text-info">{frontmatter.availabilityResponsivenessTitle}</h3>
              <p>{frontmatter.availabilityResponsivenessText}</p>
            </Col>
            {/* <Col lg={4} className="text-center"> */}
            {/*  <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-info" /> */}
            {/*  <h4>{markdownFileText.frontmatter.paperPlaneIconCaption}</h4> */}
            {/*  <p>{markdownFileText.frontmatter.paperPlaneIconText}</p> */}
            {/* </Col> */}
          </Row>
        </Container>
      </div>
      <div>
        <Container className="p-4 text-center">
          {/* <Row className="mt-4"> */}
          {/*  <Col lg={12} className="p-3 text-center"> */}
          {/*    <LocalizedLink to="/qui-sommes-nous/labels#lse"> */}
          {/*      <img */}
          {/*        src={lseLogo} */}
          {/*        alt="LSE Logo" */}
          {/*        className="" */}
          {/*      /> */}
          {/*    </LocalizedLink> */}
          {/*  </Col> */}
          {/*  /!* <Col lg={4} className="text-center"> *!/ */}
          {/*  /!*  <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-info" /> *!/ */}
          {/*  /!*  <h4>{markdownFileText.frontmatter.paperPlaneIconCaption}</h4> *!/ */}
          {/*  /!*  <p>{markdownFileText.frontmatter.paperPlaneIconText}</p> *!/ */}
          {/*  /!* </Col> *!/ */}
          {/* </Row> */}
          {/* <LocalizedLink to="/qui-sommes-nous/certifications/#lse" className="width-7
          d-inline-block"> */}
          {/*  <img */}
          {/*    src={lseLogo} */}
          {/*    alt="LSE Logo" */}
          {/*    className="w-100 h-auto" */}
          {/*  /> */}
          {/* </LocalizedLink> */}

          <LocalizedLink to="/qui-sommes-nous/certifications/#lse" className="d-inline-block">
            <Img fluid={data.lseLogo.childImageSharp.fluid} className="width-7 h-auto" />
          </LocalizedLink>
        </Container>
      </div>
      {/* <Container className="px-4 pb-4"> */}
      {/*  <h2 className="pt-4 h3 text-center"> */}
      {/*    Nos certifications */}
      {/*  </h2> */}
      {/*  <Row className="mt-4 justify-content-lg-around"> */}
      {/*    <Col lg={3} className="p-1"> */}
      {/*      /!* <FontAwesomeIcon icon={faDatabase} size="3x" className="text-info" /> *!/ */}
      {/*      <h3 className="h-test text-info">Constance du service</h3> */}
      {/*      <Img fluid={data.lseLogo.childImageSharp.fluid} className="" /> */}
      {/*    </Col> */}
      {/*    <Col lg={3} className="p-1"> */}
      {/*      /!* <FontAwesomeIcon icon={faChartLine} size="3x" className="text-info" /> *!/ */}
      {/*      <h3 className="h-test text-info">Des interlocuteurs experts</h3> */}
      {/*      <p className="text-justify"> */}
      {/*        Les interlocuteurs référents de nos clients sont */}
      {/*        <strong> toujours </strong> */}
      {/*        des consultants disposant d'un haut niveau d'expérience et d'expertise. */}
      {/*      </p> */}
      {/*    </Col> */}
      {/*    <Col lg={3} className="p-1"> */}
      {/*      <h3 className="h-test text-info">Disponibilité et réactivité</h3> */}
      {/*      <p className="text-justify"> */}
      {/*        Nous restons en contact étroit avec nos clients pendant toute la durée des projets,
      */}
      {/*        et sommes disponibles même en cas de sollicitation imprévue */}
      {/*      </p> */}
      {/*    </Col> */}
      {/*    /!* <Col lg={4} className="text-center"> *!/ */}
      {/*    /!*  <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-info" /> *!/ */}
      {/*    /!*  <h4>{markdownFileText.frontmatter.paperPlaneIconCaption}</h4> *!/ */}
      {/*    /!*  <p>{markdownFileText.frontmatter.paperPlaneIconText}</p> *!/ */}
      {/*    /!* </Col> *!/ */}
      {/*  </Row> */}
      {/* </Container> */}
      {/* </BackgroundImage> */}
      {/* <Container */}
      {/*  // className="bg-white p-4 min-vh-100 home-page-content" */}
      {/*  className="p-4 min-vh-100" */}
      {/* > */}
      {/*  <h1 className="h1-text-color"> */}
      {/*    <FontAwesomeIcon className="mr-3" icon={faHome} size="xs" /> */}
      {/*    {markdownFileText.frontmatter.mainTitle} */}
      {/*  </h1> */}
      {/*  <h2 className="h3 text-center mt-3">{markdownFileText.frontmatter.secondaryTitle}</h2>
      */}
      {/*  /!* <p className="text-center">{markdownFileText.frontmatter.textAfterSecondaryTitle}</p>
      */}
      {/*  *!/ */}
      {/*  /!* Three columns of text below the carousel *!/ */}
      {/*  <Row className="mt-4 justify-content-lg-around"> */}
      {/*    <Col lg={5} className="text-center border-desktop rounded-lg p-4"> */}
      {/*      /!* <FontAwesomeIcon icon={faDatabase} size="3x" className="text-info" /> *!/ */}
      {/*      <h4>{markdownFileText.frontmatter.databaseIconCaption}</h4> */}
      {/*      <p>{markdownFileText.frontmatter.databaseIconText}</p> */}
      {/*    </Col> */}
      {/*    <Col lg={5} className="text-center border-desktop rounded-lg p-4"> */}
      {/*      /!* <FontAwesomeIcon icon={faChartLine} size="3x" className="text-info" /> *!/ */}
      {/*      <h4>{markdownFileText.frontmatter.reportingIconCaption}</h4> */}
      {/*      <p>{markdownFileText.frontmatter.reportingIconText}</p> */}
      {/*    </Col> */}
      {/*    /!* <Col lg={4} className="text-center"> *!/ */}
      {/*    /!*  <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-info" /> *!/ */}
      {/*    /!*  <h4>{markdownFileText.frontmatter.paperPlaneIconCaption}</h4> *!/ */}
      {/*    /!*  <p>{markdownFileText.frontmatter.paperPlaneIconText}</p> *!/ */}
      {/*    /!* </Col> *!/ */}
      {/*  </Row> */}
      {/*  /!* <hr className="my-5" /> *!/ */}
      {/*  <h2 className="h3 text-center mt-5">{markdownFileText.frontmatter.secondaryTitle2}</h2>
      */}
      {/*  <Row className="mt-4 justify-content-lg-around"> */}
      {/*    <Col lg={3} className="text-center border-desktop rounded-lg p-4"> */}
      {/*      /!* <FontAwesomeIcon icon={faDatabase} size="3x" className="text-info" /> *!/ */}
      {/*      <h4>{markdownFileText.frontmatter.serviceCaption}</h4> */}
      {/*      <p>{markdownFileText.frontmatter.serviceText}</p> */}
      {/*    </Col> */}
      {/*    <Col lg={3} className="text-center border-desktop rounded-lg p-4"> */}
      {/*      /!* <FontAwesomeIcon icon={faChartLine} size="3x" className="text-info" /> *!/ */}
      {/*      <h4>{markdownFileText.frontmatter.expertsCaption}</h4> */}
      {/*      <p>{markdownFileText.frontmatter.expertsText}</p> */}
      {/*    </Col> */}
      {/*    <Col lg={3} className="text-center border-desktop rounded-lg p-4"> */}
      {/*      /!* <FontAwesomeIcon icon={faDatabase} size="3x" className="text-info" /> *!/ */}
      {/*      <h4>{markdownFileText.frontmatter.reactiviteCaption}</h4> */}
      {/*      <p>{markdownFileText.frontmatter.reactiviteText}</p> */}
      {/*    </Col> */}
      {/*    /!* <Col lg={4} className="text-center"> *!/ */}
      {/*    /!*  <FontAwesomeIcon icon={faPaperPlane} size="3x" className="text-info" /> *!/ */}
      {/*    /!*  <h4>{markdownFileText.frontmatter.paperPlaneIconCaption}</h4> *!/ */}
      {/*    /!*  <p>{markdownFileText.frontmatter.paperPlaneIconText}</p> *!/ */}
      {/*    /!* </Col> *!/ */}
      {/*  </Row> */}
      {/*  /!* <Row> *!/ */}
      {/*  /!*  <Col md={7}> *!/ */}
      {/*  /!*    <h2 className="text-muted">{markdownFileText.frontmatter.missionTitle}</h2> *!/
      */}
      {/*  /!*    <p className="lead">{markdownFileText.frontmatter.missionText}</p> *!/ */}
      {/*  /!*  </Col> *!/ */}
      {/*  /!*  <Col md={5}> *!/ */}
      {/*  /!*    /!* <Image src={datArtFeature1} alt="First feature" *!/ */}
      {/*  /!*      rounded className="img-fluid" /> *!/ *!/ */}
      {/*  /!*    <Img fluid={data.datArtFeature1Image.childImageSharp.fluid} className="rounded" />
      */}
      {/*  *!/ */}
      {/*  /!*  </Col> *!/ */}
      {/*  /!* </Row> *!/ */}
      {/*  /!* <hr className="my-5" /> *!/ */}
      {/*  /!* <Row> *!/ */}
      {/*  /!*  <Col md={{ span: 7, order: 2 }}> *!/ */}
      {/*  /!*    <h2 className="text-muted">{markdownFileText.frontmatter.assetsTitle}</h2> *!/ */}
      {/*  /!*    <p className="lead">{markdownFileText.frontmatter.assetsText}</p> *!/ */}
      {/*  /!*  </Col> *!/ */}
      {/*  /!*  <Col md={{ span: 5, order: 1 }}> *!/ */}
      {/*  /!*    /!* <Image src={datArtFeature2} alt="Second feature" *!/ */}
      {/*  /!*    rounded className="img-fluid" /> *!/ *!/ */}
      {/*  /!*    <Img fluid={data.datArtFeature2Image.childImageSharp.fluid} className="rounded" />
      */}
      {/*  *!/ */}
      {/*  /!*  </Col> *!/ */}
      {/*  /!* </Row> *!/ */}
      {/*  /!* <hr className="my-5" /> *!/ */}
      {/*  /!* <Row> *!/ */}
      {/*  /!*  <Col md={7}> *!/ */}
      {/*  /!*    <h2 className="text-muted">{markdownFileText.frontmatter.historyTitle}</h2> *!/
      */}
      {/*  /!*    <p className="lead">{markdownFileText.frontmatter.historyText}</p> *!/ */}
      {/*  /!*  </Col> *!/ */}
      {/*  /!*  <Col md={5}> *!/ */}
      {/*  /!*    /!* <Image src={datArtFeature3} alt="Third feature" *!/ */}
      {/*  /!*      rounded className="img-fluid" /> *!/ *!/ */}
      {/*  /!*    <Img fluid={data.datArtFeature3Image.childImageSharp.fluid} className="rounded" />
      */}
      {/*  *!/ */}
      {/*  /!*  </Col> *!/ */}
      {/*  /!* </Row> *!/ */}
      {/*  /!* <hr className="my-5" /> *!/ */}
      {/*  /!* <Row> *!/ */}
      {/*  /!*  <Col md={{ span: 7, order: 2 }}> *!/ */}
      {/*  /!*    <h2 className="text-muted">{markdownFileText.frontmatter.philosophyTitle}</h2> *!/
      */}
      {/*  /!*    <p className="lead">{markdownFileText.frontmatter.philosophyText}</p> *!/ */}
      {/*  /!*  </Col> *!/ */}
      {/*  /!*  <Col md={{ span: 5, order: 1 }}> *!/ */}
      {/*  /!*    /!* <Image src={datArtFeature4} alt="Fourth feature" *!/ */}
      {/*  /!*    rounded className="img-fluid" /> *!/ *!/ */}
      {/*  /!*    <Img fluid={data.datArtFeature4Image.childImageSharp.fluid} className="rounded" />
      */}
      {/*  *!/ */}
      {/*  /!*  </Col> *!/ */}
      {/*  /!* </Row> *!/ */}
      {/* </Container> */}
    </Layout>
  );
}

Home.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`
    fragment homeHeaderFluidImageTest on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    fragment lseLogoFluidImage on File {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }

#    fragment homeHeaderFixedImage on File {
#        childImageSharp {
#            fixed(height: 350, quality: 100) {
#                ...GatsbyImageSharpFixed_withWebp
#            }
#        }
#    }

#      fragment logoFluidImage on File {
#        childImageSharp {
#          fluid(quality: 100) {
#            ...GatsbyImageSharpFluid
#          }
#        }
#      }

    #    fragment headerFluidImage on File {
    #        childImageSharp {
    #            fluid(quality: 100) {
    #                ...GatsbyImageSharpFluid_withWebp
    #            }
    #        }
    #    }

#    fragment featureFuildImage on File {
#        childImageSharp {
#            fluid(maxWidth: 800, quality: 100) {
#                ...GatsbyImageSharpFluid_withWebp
#            }
#        }
#    }

#    fragment lseFuildImage on File {
#        childImageSharp {
#            fluid(maxWidth: 800, quality: 100) {
#                ...GatsbyImageSharpFluid
#            }
#        }
#    }

    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "home"}}) {
            frontmatter {
                title
                description
                
                mainTitle

                expertiseTitle
                sapTitle
                sapText
                microsoftTitle
                microsoftText
                
                commitmentsTitle
                serviceContinuityTitle
                serviceContinuityText
                expertConsultantsTitle
                expertConsultantsText
                availabilityResponsivenessTitle
                availabilityResponsivenessText
            }
        }
        
        datArtHomeHeaderImage: file(relativePath: {regex: "/datArtHomeHeaderImage/"}) {
            ...homeHeaderFluidImageTest
        }

        lseLogo: file(relativePath: {regex: "/lseLogo/"}) {
            ...lseLogoFluidImage
        }

#        sapPartnerLogo: file(relativePath: {regex: "/sapPartnerLogo/"}) {
#            ...logoFluidImage
#        }
        
#        datArtHeader1Image: file(relativePath: {regex: "/datArtHeader1/"}) {
#            ...homeHeaderFixedImage
#        }
        #        datArtHeader2Image: file(relativePath: {regex: "/datArtHeader2/"}) {
        #            ...headerFluidImage
        #        }
        #        datArtHeader3Image: file(relativePath: {regex: "/datArtHeader3/"}) {
        #            ...headerFluidImage
        #        }
        #        datArtHeader4Image: file(relativePath: {regex: "/datArtHeader4/"}) {
        #            ...headerFluidImage
        #        }
#        datArtFeature1Image: file(relativePath: {regex: "/datArtFeature1/"}) {
#            ...featureFuildImage
#        }
#        datArtFeature2Image: file(relativePath: {regex: "/datArtFeature2-1024x682/"}) {
#            ...featureFuildImage
#        }
#        datArtFeature3Image: file(relativePath: {regex: "/datArtFeature3/"}) {
#            ...featureFuildImage
#        }
#        datArtFeature4Image: file(relativePath: {regex: "/datArtFeature4/"}) {
#            ...featureFuildImage
#        }
#        #    microsoftLogo: file(relativePath: {regex: "/microsoftLogo2/"}) {
        #      ...logoFluidImage
        #    }
#        lseLogo: file(relativePath: {regex: "/lseLogo"}) {
#            ...lseFuildImage
#        }
    }
`;
